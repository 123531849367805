body {
    font-size: 15px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    background-color:#ffffff;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-weight: normal;
}
.nav-link i.ni, .nav-link i{
    padding-right: 10px;
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
	color: rgb(255, 255, 255);
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
    z-index: 9;
}
.navbar{
    padding: 0rem 0rem;
}
/* .navbar-nav .dropdown_nav.position-relative.nav-item {
    padding-top: 6px;
    padding-bottom: 6px;
} */
.bg-gradient-info {
    /* background:#234a6c !important; */
    background: #3c8dbc !important;
    /* height:45px; */
  }
.navbar .nav-item .nav-link-icon.nav-link i {
    margin-right: 0px;
    font-size: 30px;
    color: #fff;
    padding: 0;
}
.navbar-dark .navbar-nav .nav-link{
    text-transform: none;
    color: #dfeffd;
    font-weight: 400;
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #ffffff;
    background: rgba(17, 96, 141, 0.36);
}
.awssld__bullets {
    bottom: -21px !important;
}
.cursor-pointer{
    cursor: pointer;
}
.dropdown-menu-arrow.dropdown-menu.dropdown-menu-right.show {
    transform: translate3d(-148px, 44px, 0px) !important;
}
.navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    border-bottom: 0;
}
form.upload_sheet{
    max-width: 600px;

margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: 0px 0px 7px#c2c2c259;
    padding: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
}
.form-group #exampleFile {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    padding-left: 62px !important;
    /* border: 0; */
}
.file_input.form-control-label {
    font-weight: 400;
    margin-bottom: 3px;
}
.form-control-alternative:focus {
    box-shadow: none;
}
.form-group #exampleFile{
    border: 1px solid#ccc !important;
    /* padding: 10px; */
    /* border-radius: 5px; */
    padding-left: 44px !important;
    width: 100%;
    font-size: 0.875rem;
    color:#8898aa;
    text-transform: capitalize;
}
.brandOrder.form-group #exampleFile {
	padding-left: 34px !important;
}
.addBrandList.form-group input#exampleFile {
    padding-left: 40px !important;
}
@-moz-document url-prefix() {
    .form-group #exampleFile{
        padding-left: 62px !important;
    }
    .brandOrder.form-group #exampleFile {
        padding-left: 54px !important;
    }
    .addBrandList.form-group input#exampleFile {
        padding-left: 52px !important;
    }
    .ul_links.navbar-nav{
        top: 45px;
    }
  }
.card .table th {
    font-weight: bold;
    position: relative;
}
.card .table td, .card .table th{
    font-size: 14px;
    text-align: left;
    /* text-transform: capitalize; */
}
.card .table th{
    text-transform: capitalize;
}
.table thead tr {
    /* background:#d0e2f2; */
    background:#d7eafcc9;
}
.table thead tr th {
    color:#143275;

}
.card .table td .fas.fa-pen {
    color:blue;
    padding-right: 0 !important;
}
.card .table td .fas.fa-plus-circle {
    color: #109add;
}
.card .table td .fas.fa-minus-circle {
    color: #f15d5d;
}
.card .table td i, .card .table td input {
    margin: 0 5px;
}
.card .table td .fas.fa-eye {
	color: #4dba50;
}
.table th.colum_width {
    width: 52px;
}
.search_bar {
    align-items: center;
    max-width: 220px;
}

.Toastify__toast.Toastify__toast--success {
    background: #4dba50 !important;
}
.search_bar input {
    padding: 8px;
    height: auto;
}
ul.pagination li button {
    border: 0;
    box-shadow: none;
    width: auto !important;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    padding-right: 1.5rem;
    padding-top: 0rem;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.dataTables_info{
    padding-top: 2rem;;
}
.page-link:hover{
    background-color:transparent
    }
    .page-item.active .page-link, .btn-primary, .btn-primary:hover {
        background-color: #f15d5d !important;
        border-color: #f15d5d;
    }
    .pagination .page-item.active .page-link {
        width: 35px !important;
    }
.table-bordered th, .table-bordered td{
    border: 1px solid #dddddd !important;
}
.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: 
    rgb(255, 255, 255);
}
.table-striped tbody tr:nth-of-type(2n+2) {
    background-color: rgba(234, 234, 234, 0.3);
}
tbody + thead{
    display: none;
}
.dash-supplier{
    padding: 0.75rem 1.5rem;
}
.dash-supplier:nth-of-type(2n+2) {
    background: #f8f8f8;
}
.icon-file{
    cursor: pointer;
    background: #244a6b;
    color: #fff;
    border-radius:  20px;
    padding: 5px 9px;
    top: -15px;
    right: 0px ;
}
.card .table td, .card .table th {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.table thead tr th {
    padding: 0.5rem;
}
.edittable Table thead tr {
    background: #47576a;
}
.edittable Table thead tr th {
    color: #fff;
    border: 1px solid #e9ecef;
    border-top: 0;
    background: transparent;
    padding: 16px;
    white-space: nowrap;
}
.edittable Table tbody tr td {
    border: 1px solid#e9ecef;
    white-space: nowrap;
}
.edittable button > span > svg {
    color: 
    #47576a;
}
.edittable Table thead tr th span:hover {
    color: 
    #ffffffd6;
}
.edittable Table thead tr th span svg {
    /* display: none; */
    position: absolute;
    right: 0;
    font-size: 16px;
    top: -13px;
    left: 0;
    margin: 0 auto;
}
/* .TableHeaderCell-cell-279:first-child {
    padding-left: 10px;
} */
.edittable Table {
    border-collapse: collapse;
}
.edittable Table tr th, .edittable Table tbody tr td {
    text-transform: uppercase;
    text-align: center;
}
.edittable Table tr th > div {
    justify-content: center !important;
    /* display: inline-block; */
}
.edittable Table tr th > div > div {
    justify-content: center;
}
/* .edittable Table thead tr th:first-child {
    padding-left: 10px;
} */
.edittable Table tbody tr td:first-child {
    padding-left: 10px;
}
.MuiTableCell-paddingCheckbox {
    width: 100%;
    padding: 0;
}
.main-table table tfoot tr td div > span {
	display: none;
}
.main-table table tfoot tr td div .MuiSelect-root.MuiSelect-select.MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInputBase-inputSelect {
	border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 35px;
}
.main-table table tfoot tr td div svg {
	right: 37px;
	top: 2px;
}
table tr:nth-of-type(2n+2) {
    background-color: 
    rgba(0, 0, 0, 0.05);
}
.edittable Table tbody tr td > div {
    width: 100%;
}
.ul_links.navbar-nav {
    position: absolute;
    background:#3c8dbc;
    top: 44px;
    z-index: 99;
    width: 200px;
    display: none;
    padding-top: 12px;
}
.sub_menu.navbar-nav {
	position: absolute;
	background: #2a80b3;
	top: 0;
	z-index: 99;
	width: 205px;
	display: none;
	padding: 0;
	left: 200px;
}
.ul_links.navbar-nav li .sub_li:hover .sub_menu.navbar-nav {
    display: block;
}
li.dropdown_nav:hover .ul_links.navbar-nav {
	display: block !important;
}
li.dropdown_nav.linkOrder:hover .ul_links.navbar-nav {
	display: none !important;
}
.ul_links.navbar-nav li {
    width: 100%;
}
.ul_links.navbar-nav li .nav-link {
    padding-bottom: 10px;
    color: #fff;
    padding-top: 10px;
}
.table-responsive > .table-bordered {
    margin-bottom: 20px;
    margin-top: 10px;
}
.ul_links.navbar-nav li a {
	font-size: 14px;
}
form.upload_sheet .btn.btn-primary {
    padding: 11px 13px;
}
.table th, .table td{
    vertical-align: middle !important;
}
.card .table th {
    font-weight: 600 !important;
    color: #284b68;
    font-size: 13px;
}
.nav-pills .nav-link{
    padding: 0.3em 1rem;
}
.dataTables_length,.dataTables_filter {
    padding: 0;
}
.btn{
    font-weight: normal !important;
}
.main-content .row {
    margin: 0 !important;
}
.main-content{
    background-color: #fff;
}
.image_col.col {
	word-wrap: break-word;
}
.image_col.col .responsive-img {
	max-width: 55px;
}
.main-content .row .col {
	padding: 0;
}
.main_col_form > div > div > div, .main_col_form .form-group .col-lg-12, .main_col_form .row .col-lg-12 {
	padding: 0;
}
#navbar-main .container-fluid {
    width: 100%;
    padding: 0 15px;
}
.text-black {
    color: #525f7f;
}
.text-black i {
    font-size: 16px;
}
.action_btn {
    /* width: 50px;
    height: 40px;
    text-align: center; */
    padding: 0;
    /* justify-content: center;
    flex-flow: column;
    display: inline-flex;
    background: #fff; */
}
.action_btn:hover {
    box-shadow: none;
}
.action_btn i:hover {
    color: #087cdd;
}

.dataTables_filter {
    display: flex;
    justify-content: flex-end;
}
.dataTables_length label select {
    width: 170px;
}
.dataTables_length label select {
    width: 170px;
    margin-left: 18px !important;
}
.card_border {
    border: 0;
    /* border-bottom: 1px solid #00000026; */
    border-radius: 0;
}
.dataTables_filter label, .dataTables_length label {
    display: flex;
    align-items: center;
}
.dataTables_filter label input {
    margin-left: 18px;
}
.page-item.active .page-link, .btn-primary,  .btn-primary:hover {
background-color:#f15d5d !important;
border-color:#f15d5d ;
}
.page-item.active .page-link:hover {
	color: #fff;
}
.navbar-dark .navbar-nav li a.active, .navbar-dark .navbar-nav li a:hover {
    /* border-bottom: 2px solid; */
    padding-bottom: 10px;
}
.ul_links.navbar-nav li a {
    border-bottom: 2px solid transparent;
}
.navbar-dark .navbar-nav li a, .navbar-dark .navbar-nav li a {
    border-bottom: 2px solid transparent;
}
.navbar-expand-md .navbar-nav .nav-link{
    padding: 10px 1rem;
}
/* .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link:hover {

    padding-bottom: 10px;

} */
.form_section {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 20px;
    /* border: 1px solid #e6e6e6; */
    border-radius: 9px;
    background-color:#fff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
form textarea {
    height: 150px !important;
    border-radius: 3px !important;
}
form input, form textarea {
    box-shadow: none;
    border: 1px solid #e1e1e1;
    /* text-transform: uppercase; */
}
form input, form select{
    /* height: 38px; */
    /* padding: 6px 10px !important; */
    padding: 0.625rem 0.75rem;
    border-radius: 3px !important;
}
form label {
    text-transform: capitalize;
    font-weight: normal;
    margin-bottom: 3px;
}
.form_section .form-group{
    margin-bottom: 1.0rem;
}
.back_btn {
    position: absolute;
    right: 20px;
    width:auto;
}
.form_section .detail .card-body {
    padding: 0.5rem;
}
.detail_page.card-body {
    max-width: 950px;
    margin: 0 auto;
    width: 100%;
}
.supplier-detail_page.card-body {
    max-width: 100% !important;
    margin: 0 auto;
    width: 100%;
}

/* Supplier view page css */
.box-wrapper {
    margin-bottom: 18px;
}
.supplier-basic-information .supplier-basic-details p{
    margin-bottom: 0px;
}
.supplier-basic-information h2 {
    background: #3c8dbc;
    padding: 0px 12px;
    margin-bottom: 0;
    color: #fff;
}

.supplier-basic-information {
    border: 1px solid #3c8dbc;
    border-radius: 4px;
}
.supplier-basic-details {
    padding: 6px 12px;
}

.modal-dialog.clssName.main_feb_model .modal-content .detail_page.card-body {
	padding: 0 15px;
}
.detail_page.card-body .card-body {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
}
.detail_page.card-body .card-body h4 {
    width: 100px;
}
.detail_page.card-body .card-body p {
    width: 80%;
    text-align: right;
}
.detail_page.card-body .row {
    justify-content: space-around;
}
.curcle_img.col img {
	width: 220px;
	height: 220px;
	border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}
p.position-absolute.remove {
    top: -13px !important;
    right: 2px !important;
    width: 28px  !important;;
    background-color: #fff;
}
.select_box.form-group > div > div {
    /* height: 38px; */
    padding: 3px 12px;
}
.select_box.form-group > div .searchBox {
    padding: 8px 0 !important;
    /* height: 35px; */
    margin-top: 0px;
    width: 100%;
    
}
.select_box.form-group > div > div span.chip {
    margin: 2px;
}
.modal-backdrop{
    z-index: 99 !important;
}
.card .table td img {
    margin-right: 10px;
}
.table td, .table th{
    white-space: initial;
}
.sorting::before, .sorting_asc:before, .sorting_desc:before {
    right: 5px;
    content: "\f0de";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-size: 1rem;
    position: absolute;
    opacity: .3;
}
.sorting::after, .sorting_desc:after, .sorting_asc:after {
    content: "\f0dd";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    right: 5px;
    font-size: 1rem;
    position: absolute;
    opacity: .3;
}
.sorting_asc:before{
    opacity: 1;
}
.sorting_desc:after{
    opacity: 1;
}
.action_btn {
    background:transparent;
}
.card .table td .action_btn .far.fa-trash-alt {
    color: #f15d5d;
}
.card .table td .action_btn .far.fa-edit {
	color: blue;
}
.card .table td .action_btn .far.fa-eye {
	color: green;
}
.image_col.col {
    max-width: 55px;
    width: 100%;
    margin-right: 15px;
}
.word-break.col {
    word-break: break-word;
}
.order_content.word-break.col h3 {
    text-overflow: ellipsis;
    height: 25px;
    overflow: hidden;
}
.header.bg-gradient-info.custom_login {
	height: initial;
}

.main-content.login_page {
	background-color: initial;
}
.flex-flow-row {
	flex-flow: row;
}
.text-decoration-underline {
	text-decoration: underline;
}
.order_list_pdf .table thead tr th, .order_list_pdf .table td, .order_list_pdf .table th {
    padding: 0.5rem;
}
table.sec_col_nth tr:nth-of-type(2n+2) {
	background-color: rgba(255, 255, 255, 0.05);
}
.po_address_col p {
	font-size: 14px;
	border: 1px solid #ccc;
	padding: 0 6px;
	color: #000;
}
.address_same tr th {
    height: 38px;
}
.address_same tr th, .address_same tr {
	border-bottom: 1px dotted #cccaca;
}
.address_same {
	border: 1px solid #ddd !important;
}
.address_same th {
	border: 1px dotted #cccaca;
}
.card .tableSize .table th:nth-last-child(3), .card .tableSize .table th:nth-last-child(2), .card .imageSize .table th:nth-child(2) {
    width: 130px;
}
.card .table td:last-child, .card .table th:last-child, .card .table td:first-child, .card .table th:first-child,.card .imageSize .table th:nth-child(2),.card .imageSize .table td:nth-child(2) {
    text-align: center;
}
.table thead th:first-child {
    width: 35px;
}
.table thead th:last-child {
    width: 134px;
}
.crossBtn {
    position: absolute;
    top: -5px;
    right: -15px;
    text-align: right;
}
.sec_col_nth.address_same tr th {
	font-weight: 400 !important;
}
.text-bold {
	font-weight: 500;
}
.imp_note_sec.row p {
    font-size: 14px;
    margin-bottom: 0px;
}
.imp_note_sec.row .authorised_sig {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;
}
.imp_note_sec.row {
	border: 1px solid #ccc;
	/* padding: 5px 0; */
}
.authorised_sig {
	border-left: 1px solid #ccc;
}
.sig_box {
	min-height: 62px;
}
.sig_box p {
	font-size: 14px;
}
.imp_note_po p {
	font-size: 14px;
}
.back_btn ul li {
	display: flex;
	flex-flow: row;
}
.form-control-alternative {
	box-shadow: none;
	border: 1px solid #e1e1e1;
}
.clear_field {
    margin-top: 8px;
}
.clear_field .btn {
	min-height: 46px;
}
.dataTables_info {
    display: none;
}
/* .btn.btn-primary {
    padding: 0.3em 1rem;
} */
/* .btn.btn-secondary{
    padding: 0.3em 1rem;
} */
/* .card .table td input[type="checkbox"]{
    -webkit-appearance:none;
    position: relative;
    vertical-align: text-top;
  }
  .card .table td input[type="checkbox"]:after{
    -webkit-transform-style: preserve-3d;
    -webkit-transition: 1s;
     -webkit-transform: rotatey(-180deg);
     -webkit-perspective: 800;
     background:white;
     border: 2px solid #234a6c;
     line-height:20px;
     width:14px;
     height:14px;
     position: absolute;
     z-index: 1;
     text-align: center;
     content:"";
     margin-top: 0px;
     font-size: 10px;
    font-weight: bold;
    line-height: 9px;
  }
  .card .table td input[type="checkbox"]:checked:after{
    -webkit-transform: rotatey(0deg);
      background: #234a6c;
      border: 2px solid #234a6c;
      color: white;
      cursor: pointer;
      content:"\2713";
      font-size: 10px;
    font-weight: bold;
    line-height: 9px;
  } */
  .modal_detail_tabel table tr th {
    font-size: 0.8125rem;
    padding: 10px;
    text-transform: capitalize
}
.border-radius-inherit {
	border-radius: inherit;
}
.modal-content .detail_page.card-body .card-body p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: normal;
    width: 70%;
    text-align: right;
}
.modal-content .detail_page.card-body .card-body {
    margin-bottom: 8px;
    padding: 0 15px !important;
}
.modal-dialog.clssName.main_feb_model .modal-content .detail_page .card-body {
	/* display: flex;
    align-items: center; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 95%;
    margin: 8px auto;
}
.modal_detail_tabel table tbody tr td{
    padding: 10px;
}
.modal-dialog.clssName.main_feb_model .detail_page.card-body h3{
    background-color:#1d3e5b;
    color:#fff;
    padding: 9px 15px;
}
.modal-dialog.clssName.main_feb_model .detail_page.card-body h3.first{
    border-radius: 7px 0px 0 0px !important;
}
.modal-dialog.clssName.main_feb_model .detail_page.card-body h3.second{
    border-radius: 0px 7px 0 0px !important;
}
.modal-dialog.clssName.main_feb_model .modal-content .detail_page.card-body .card-body span {
    font-size: 14px;
    color: #182a3a;
    width: 130px;
    
}
/* .modal-dialog.clssName.main_feb_model .modal-content .modal-body, .modal-content .modal-footer {
	padding: 0 15px;
} */
.modal-dialog.clssName.main_feb_model .modal-content .card_border .detail_page .p-0 {
	padding: 0 !important;
}
.modal-dialog.clssName.main_feb_model .modal-content .modal-body, .modal-dialog.clssName.main_feb_model.modal-footer {
	padding: 0 25px;
}
.modal-dialog.clssName.main_feb_model .modal-content{
    padding-bottom: 25px;
}
.text-light {
    color: #879099 !important;
}
.modal-dialog.manage_varients .modal-body, .modal-dialog.manage_varients .modal-footer {
	padding: 1.5rem;
}
.modal-dialog.manage_varients .modal-content {
	padding-bottom: 0;
}
.detail_page p {
	font-size: 14px;
}
.form_section.user_table_cust {
	padding: 30px 5px;
}
.form-group label {
	margin-bottom: 3px;
}
/* .custom_label_input {
	padding: 0.72rem 0.75rem;
	border-radius: 5px;
	background: #fff;
	border: 1px solid #ccc;
	position: absolute;
	margin-bottom: 0;
	top: 0;
	left: 0;
    width: 100%;
    font-size: 0.875rem;
    color:#8898aa;
    font-weight: 400;
} */
.custom_label_input {
	padding: 6px;
	border-radius: 5px;
	background: #fff;
	position: absolute;
	top: 7px;
	left: 14px;
	font-size: 0.875rem;
	color: #8898aa;
	font-weight: 400;
	margin-bottom: 0 !important;
    padding-left: 0px;
    max-width: 118px;
    width: 100%;
}
.disabledAction{
    color:#999 !important
}
.custom_label_input i {
	padding-right: 5px;
}
.custom_nav_image {
	align-items: center;
}
.custom_nav_image .avatar.avatar-sm {
    margin-right: 10px;
}
.avatar img{
    height: 100%;
    object-fit: cover;
}
.avatar.avatar-sm.logo_image img {
    border-radius: 0;
    object-position: 50% 25%;
}
.avatar.avatar-sm.logo_image {
	border-radius: 0;
	max-width: 80px;
	width: 100%;
}
#sidenav-main .custom_nav_image {
	width: 85%;
}
.profileTitle{
    width: 80%;
    text-align: right;
}
.headerProfile{
    width: 25%;
}
.profileName{
    margin: 0px;
    padding-right: 15px;
    color: #dfeffd;
    text-transform: capitalize;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.multi_images_upload {
	display: flex;
	flex-wrap: wrap;
}
.multi_images_upload .upload_img {
	padding: 5px;
	width: 25%;
}
.multi_images_upload .upload_img img {
	height: 100px;
	object-fit: cover;
}
.card .table td input {
	width: 100%;
	min-width: 70px;
	margin-left: 0;
}
.justify-contant-flex-end{
    justify-content: flex-end;
}
.d-flex.search_bar label {
	margin-bottom: 0;
}
/***************sticky header************************/
#navbar-main {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 99;
}
.navbar-expand-md{
    justify-content: space-between !important;
}
.nav-link i.fas{
    top: 0;
    padding-right: 10px;
}
.mobil-res.respoMargin.row {
    margin-top: 45px !important;
}
.close > span{
    font-size: 2.25rem;
}
.modal-dialog.modal-dialog-centered {
    width: 90% !important;
    margin: 0 auto;
    max-width: 650px;
}
.modal-content .modal-body .mobile_sec_col h2.text-center {
	text-align: left !important;
}
.btn + .action_btn.text-black.btn.btn-outline- {
    margin-left: 0;
}
.btn:not(:last-child) {
    margin-right: 0rem;
}
    /* .modal-dialog.modal-dialog-centered .row > div {
        padding-right: 15px !important;
        padding-left: 15px !important;
    } */
    .modal-dialog .modal-content form {
        overflow: auto;
        width: 100%;
        padding: 20px;
    }
    
.tooltip1 {
    position: relative !important;
    display: inline-block !important;
    border-bottom: 0px dotted black !important;
  }
  
  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }
  .tooltip1 .tooltiptext.textFLow {
    visibility: hidden;
    width: 372px;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 9px;
    position: absolute;
    z-index: 1;
    max-height: 182px;
    overflow: auto;
  }
  .tooltip1:hover .tooltiptext {
    visibility: visible !important;
  }
  .varients_cols:last-child {
    margin-bottom: 0;
}
.varients_cols {
    margin-bottom: 6px;
}
.varients_cols {
    display: flex;
    flex-flow: row;
}

.italic-message{
    font-style: italic;
    color: red;
    font-size: 10px;
}
.delete-confirmation .modal-content {
    width: auto;
    text-align: center;
}
.delete-confirmation  .pt-0.modal-footer {
    justify-content: center;
}
.delete-confirmation .delete-icon {
    font-size: 60px;
    color: #f15d5d;
}
.warring-message {
    font-size: 30px;
    padding: 10px;
}
.delete-confirmation button.btn {
    width: 127px;
    border-radius: 3px;
    padding: 10px;
    border: none;
}
.delete-confirmation button.btn.btn-secondary {
    background: #c1c1c1;
}
.delete-confirmation button.btn.btn-primary {
    background: #f15d5d !important;
}
.btn.btn-secondary, .btn.btn-primary{
    /* padding: 0.3em 1rem; */
    border-radius: 3px;
        padding: 9px;
        border: 0px;
}
/* button {
        border-radius: 3px;
        padding: 9px;
} */
.custom-submit .submit-button {
    width: 150px;
}
.custom-submit {
    text-align: center;
    width: 100%;
}

.full-width {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 12px;
}
button.add-more-button {
    float: right;
    width: 32px;
    height: 32px;
    font-size: 21px;
    padding: 0 !important;
    font-weight: 600 !important;
    border-radius: 100% !important;
}
.button-remove {
    padding: 9px!important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin-top: 0px!important;
    font-size: 18px;
    border-left: none!important;
    background: #f15d5d!important;
    border-color: #f15d5d!important;
    color: #fff;
    font-weight: 600;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    float: right;
}
.variants-select {
    width: 96%;
    display: inline-block;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: #8898aa;
    font-size: 0.875rem;
}
.dashboard-table th, .dashboard-table td {
    width: auto !important;
    text-align: left !important;
}
td.dashboard-supplier-img {
    width: 50px !important;
}
.waring-text {
    color: #f15d5d;
}
.fileUpload{
    text-align: center;
    color: #f15d5d;
    cursor: pointer;
    font-size: 18px;
    margin: 0px;
}
.back-button{
    width:100px;
}
.test_heading {
    width: 150px;
    font-weight: 600;
    float: left;
}
.mb-3p {
    margin-bottom: 3px
}

.padding-button {
    padding: 12px !important
}
button.cutsomLink.btn {
	background: transparent;
	box-shadow: none;
	color: #f15d5d;
    text-decoration: underline;
    font-weight: 500 !important;
}
button.cutsomLink:active:focus {
	background: transparent !important;
	color: #f15d5ddb !important;
}
/********************************************/
/****************************Media screens*****************************/
@media screen and (max-width: 1700px) {
    .sub_menu.navbar-nav{
        right: 200px;
        left: initial;
    }
    .custom_nav_image .avatar.avatar-sm {
        margin-right: 10px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        font-size: 13px;
        padding: 11px 7px !important;
    }
}
@media screen and (max-width: 1390px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding: 11px 15px !important;
        font-size: 13px;
    }
    .card-dashboard h5 {
        font-size: 11px;
    }
    .headerProfile.nav {
        width: 20%;
    }
    .profileTitle {
        width: 65%;
    }
    #sidenav-main .custom_nav_image {
        width: 80%;
    }
    .nav-link i.ni, .nav-link i, .nav-link i.fas{
        padding-right:5px;
    }
    .custom_nav_image .avatar.avatar-sm {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1279px) {
    .ul_links.navbar-nav{
        right: 0;
    }
    
}
@media screen and (max-width: 1251px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding: 12px 4px !important;
        font-size: 11px;
    }
    .nav-link i.ni, .nav-link i {
        padding-right: 6px;
    }
    /* .navbar-nav .dropdown_nav.position-relative.nav-item{
        padding-top: 8px;
    } */
}
@media screen and (max-width: 1169px) {
    .custom_nav_image .avatar.avatar-sm {
        margin-right: 5px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        padding: 12px 4px !important;
        font-size: 10px;
    }
    .nav-link i.ni, .nav-link i {
        padding-right: 4px;
    }
}
@media screen and (max-width: 1109px) {
    .navbar-expand-md .navbar-nav .nav-link{
        padding: 12px 6px !important;
    }
    .custom_nav_image .avatar.avatar-sm {
        margin-right: 5px;
    }
    .nav-link i.ni, .nav-link i {
        padding-right: 4px;
    }
    .avatar.avatar-sm.logo_image {
        max-width: 58px;
    }
}
@media screen and (min-width: 768px) and (max-width:1076px) {
    .navbar-expand-md .navbar-collapse{
        display: none !important;
    }
}
@media screen and (max-width: 1076px) {
    
    .navbar-expand-md .navbar-toggler {
        display: block;
    }
    .navbar-collapse {
        width: calc(100% - 1.4rem);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1050;
        margin: .7rem;
        overflow-y: auto;
        height: auto !important;
        opacity: 0;
    }
    .navbar-collapse.collapsing, .navbar-collapse.show {
        padding: 1.5rem;
        border-radius: 0.375rem;
        background: 
    #FFF;
    box-shadow: 0 50px 100px
    rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px
        rgba(0, 0, 0, 0.1);
        -webkit-animation: show-navbar-collapse .2s ease forwards;
        animation: show-navbar-collapse .2s ease forwards;
    }
    .responsive_cross {
        position: absolute;
        right: 15px;
        top: 0px;
    }
    .navbar-collapse-header.d-md-none.responsive_cross {
        display: block !important;
    }
    .navbar-collapse .navbar-toggler {
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 14px !important;
    }
    .navbar-collapse .navbar-toggler :nth-child(1){
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    .navbar-collapse .navbar-toggler :nth-child(2) {
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
    .nav-link i.ni, .nav-link i {
        padding-right: 10px;
    }
    .ul_links.navbar-nav {
        left: 0;
        width: 100%;
    }
    .sub_menu.navbar-nav {
        right: 0;
        left: auto;
        left: initial;
        width: 85%;
        top: 38px;
    }
    
    .navbar-nav .nav-link {
        color: #172b4d !important;
    }
    .navbar-nav li a i {
        color: #2b3643 !important;
    }
    .navbar-collapse .navbar-toggler span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background:#283448;
    }
    .collapse.show.navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: column;
    }
    ul.navbar-nav{
        width: 100%;
    }
    .navbar-toggler-icon {
        background: #000;
        border-radius: 5px;
    }
    .ul_links.navbar-nav {
        left: 0;
        width: 100%;
    }
    .ul_links.navbar-nav li .nav-link {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .ul_links.navbar-nav li a i {
        color: #fff !important;
    }
    .navbar-nav .dropdown_nav.position-relative.nav-item .ul_links.navbar-nav li .nav-link {
        color: #fff !important;
    }
    .navbar-expand-md{
        justify-content: space-between;
    }
    .custom_nav_image .avatar.avatar-sm {
        margin-right: 0px;
    }
    .avatar.avatar-sm.logo_image {
        max-width: 80px;
    }
    #sidenav-main .custom_nav_image {
        width: 70%;
    }
    .navbar-expand-md .nav li .nav-link {
        padding: 0;
    }
}

@media screen and (max-width: 1023px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-size: 14px;
    }
    .ul_links.navbar-nav{
        top: 48px;
    }
    .ul_links.navbar-nav li .nav-link{
        font-size: 13px;
    }
    .nav-link i.ni, .nav-link i {
        padding-right: 5px;
    }
    .detail_page.card-body .row div {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
@media screen and (max-width: 851px) {
    .variants-select {
        width: 92%;
    }
    .modal-dialog .modal-content form {
        height: 350px;
    }
}
@media screen and (max-width: 805px) {
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 13px;
    }
    .ul_links.navbar-nav{
        right: -65px;
    }
}
@media screen and (max-width: 767px) {
    .text-sm-left{
        text-align: left !important;
    }
    .modal-dialog.clssName.main_feb_model .modal-content .detail_page .card-body{
        max-width: 100%;
    }
    form.upload_sheet{
        padding: 30px 20px;
    }
    #sidenav-main .custom_nav_image {
        width: 40%;
    } 
    .main_col_form.pad_col_none .row div {
        padding: 0;
    }
    .form_section.user_table_cust {
        padding: 30px 15px;
    }
    .modal-dialog.clssName.main_feb_model .detail_page.card-body h3.first {
        border-radius: 7px 7px 0px 0px !important;
    }
    .modal-dialog.clssName.main_feb_model .detail_page.card-body h3.second {
        border-radius: 0px 0px 0 0px !important;
    }
    .modal-dialog.clssName.main_feb_model .modal-content .modal-body, .modal-dialog.clssName.main_feb_model.modal-footer{
        padding: 0 15px;
    }
    .modal-dialog.clssName.main_feb_model .modal-content .modal-footer {
        padding: 15px !important;
    }
    .responsive_cross {
        position: absolute;
        right: 15px;
        top: 0px;
    }
    .res_padd .card-body {
        padding: 0;
    }
    .image_col.col .responsive-img, .image_col.col {
        max-width: 45px;
    }
    .navbar-dark .navbar-nav .nav-link, .ul_links.navbar-nav li a{
        border-bottom: 2px solid transparent;
    }
    .navbar-nav li a i {
        color: #2b3643 !important;
    }
    /* .ul_links.navbar-nav{
        top: 38px;
    } */
    .ul_links.navbar-nav li .nav-link {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .ul_links.navbar-nav li a {
        color: #fff !important;
    }
    .ul_links.navbar-nav li a i {
        color: #fff !important;
    }
    /* .ul_links.navbar-nav li {
        margin-left: 10px;
    } */
   
    .dataTables_length {
        padding-left: 0;
        justify-content: center;
        display: flex;
    }
    .dataTables_filter {
        text-align: center;
        padding-right: 0;
    }
    .dataTables_filter label, .dataTables_length label{
        width: 100%;
    }
    .dataTables_filter label .form-control.form-control-sm, .dataTables_length label .custom-select.custom-select-sm.form-control.form-control-sm {
        margin-left: 0px !important;
        width: 100%;
    }
    .card-body {
        padding: 1.0rem;
    }
    form > div .row div, .mobi_padd{
        padding: 0 !important;
    }
    .navbar-toggler-icon {
        background: #000;
        border-radius: 5px;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 14px;
    }
    .ul_links.navbar-nav {
        left: 0;
        width: 100%;
    }
    .text-mob-left {
        text-align: left !important;
    }
    .modal-content .detail_page.card-body .card-body {
        margin-bottom: 0px;
    }
    .form_section{
        padding: 20px 15px;
    }
    .select_box.form-group > div .searchBox {
        padding: 5px 15px !important;
        margin-top: 0px;
    }
    .detail_page.card-body .row div {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .dataTables_wrapper > .row:first-child {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
    }
    .dataTables_wrapper > .row:first-child .col-sm-12.col-md-6 {
        max-width: 50%;
    }
    .dataTables_filter label, .dataTables_length label{
        display: block;
    }
    .justify-content-end.mobileCenter {
        justify-content: center !important;
    }
}
@media screen and (max-width: 575px) { 
    .justify-contant-flex-xs-start{
        justify-content: flex-start;
    }
    .modal-dialog.manage_varients .mobil_margin {
        margin-top: 20px;
    }
    .headerProfile.nav {
        width: 60%;
    }
    .mobil-res .bg-transparent.card-header {
        padding: 15px;
    }
    .mobil-res .mb-5 {
        margin-bottom: 1rem !important;
    }
    .mobil-res.respoMargin.row {
        margin-top: 20px !important;
    }
    .mobil-res .bg-transparent.card-header .row {
        display: flex;
        flex-flow: row;
    }
    .mobil-res.row > div {
        padding: 5px;
    }
}
@media screen and (max-width: 475px) {
    .dataTables_paginate {
        padding-right: 0;
        justify-content: center;
    }
    .dataTables_info{
        padding-left: 0;
        justify-content: center;
        display: flex;
    }
    .back_btn{
        position: static;
    }
    .headerNavbar {
        margin-bottom: 5px;
    }
    .main-content .row .col .justify-content-end.nav.nav-pills li.nav-item {
        margin-bottom: 0;
    }
    .order-xl-1.col-xl-12 {
        padding: 0;
    }
    .dataTables_info {
        padding-top: 1rem;
    }
    .mobile_sec_col.row.mobile_sec_col.row .col {
        flex-basis: initial !important;
        justify-content: center;
        display: flex;
        margin-bottom: 10px;
    }
    .mobile_sec_col .col ul li.nav-item {
        margin-bottom: 0;
    }
    .mobile_sec_col.row.mobile_sec_col.row .col.back_btn {
        margin-bottom: 0;
    }
    .bg-white.border-0.mb-4.card-header {
        margin-bottom: 0.5rem !important;
    }
    .card .table td, .card .table th{
        padding-right: 1.2rem;
    }
    .avatar.avatar-sm.logo_image {
        max-width: 65px;
    }
    .mobilDirection.row .col {
        justify-content: center;
        display: flex;
        margin: 5px 0;
    }
    .card_border.card .card-header h2 {
        margin-top: 15px;
    }
    .modal-dialog.modal-dialog-centered .align-items-center.mobile_sec_col.row .col {
        flex-flow: column;
    }
    .variants-select {
        width: 88%;
    }
}
.select_box .MuiInput-underline:after, .select_box .MuiInput-underline:hover:not(.Mui-disabled):before, .select_box .MuiSelect-select:focus{
    border-bottom: 0px solid #3f51b5;
    background: transparent;
}
.table-striped tbody tr.editRow {
    background-color: #f0feec;
}
.tableEdit_table .table-responsive{
    height: 600px;
}
.tableEdit_table table.table.tableEdit {
    position: relative;
}
.pageList tr th {
    position: sticky !important;
    top: 0;
    z-index: 9;
    background: #e0eefc;
}