/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";


// React differences
@import "react/react-differences";

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.dataTables_filter {
  text-align: right;
  padding-right: 1.5rem;
  label{
    text-align: left;
    input{
      box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      border: 0;
      -webkit-transition: box-shadow .15s ease;
      transition: box-shadow .15s ease;
    }
  }
}
.dataTables_length {
  padding-left: 1.5rem;
  label{
    text-align: left;
    select{
      box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      border: 0;
      -webkit-transition: box-shadow .15s ease;
      transition: box-shadow .15s ease;
    }
  }
}
.dataTables_info{
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  font-size: 13px;
}
.dataTables_paginate{
  display: flex;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  justify-content:flex-end;
  .page-item:first-child, .page-item:last-child{
    .page-link{
      border: 0px;
      width: auto;
      span{
        width: auto;
      }
    }
  }
}
.btn-primary{
  background-color: #5e72e4 !important;
  color: #fff !important;
}
@keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
.headerNavbar{
  margin-bottom: 25px;
    .navbar-nav{
      .nav-link{
        padding: 12px 15px;
      }
    }
}
.modal-open .modal{
  z-index: 111;
}
.nav-link i.fas {
  position: relative;
  top: 2px;  
  padding-right: 10px;
 }
.loader{
  position: absolute;
  top: 0px;
  background: linear-gradient(45deg, rgb(0, 78, 135), #234d73);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 99;
  flex-direction: column;
  transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  .spinner-border{
    border: 0.25em solid #fff;
    width: 19px;
    height: 19px;
    border-right-color: transparent;
  }
}
.main-content{
  .centerForm{
    width: 75%; 
    margin: 0px auto; 
    @media only screen and (max-width:'768px') {
      width: 100%;
    }
  }
  .formPoInline{
    .col{
      padding: 0 15px !important;
      flex: 0%;
      &:last-child {
        flex: 35%;
      }
      @media only screen and (max-width:'860px') {
        flex: 19%;
      }
      @media only screen and (max-width:'768px') {
        padding: 5px 15px !important;
        width: 100%;
        flex: 100%;
      }
    }
  }
}
.card-dashboard {
  transition: background 1s;
  &:hover{
    background: #f1f9ff;
  }
  .dashboard-icon{
    border: 2px solid;
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.materialList{
  .MuiInput-formControl{
    padding: 0.625rem 0.75rem;
    border: 1px solid #cad1d7 !important;
    height: calc(2.75rem + 2px);
    border-radius: 0.375rem;
  }
  .MuiInput-formControl:before{
    content: "";
    border-bottom: 0px solid rgba(0, 0, 0, 0.42);
  }
}
.avgWidth{
  width: 60px !important;
}
.selectList{
  width: 80px !important;
}
.loader-select{
  text-align: center;
  width: 100%;
  margin-top: 10px;
  .spinner-border{
    width: 1.5rem;
    height: 1.5rem;
    border: 0.15em solid currentColor;
    border-right-color: transparent;
  }
}
.submit-button{
  .spinner-border{
    width: 1.2rem;
    height: 1.2rem;
    border: 0.15em solid currentColor;
    border-right-color: transparent;
  }
}
.inputFiled {
  background: #fff;
  border: 1px solid #e1e1e1;
  height: 30px;
}