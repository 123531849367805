table.table.table-bordered.table-hover.table-striped.dataTable thead:last-child {
    display: none !important;
}

p.position-absolute.remove {
    top: -8px;
    right: 7px;
    border: 1px solid;
    border-radius: 50%;
    width: 17%;
    text-align: center;
    cursor: pointer;
}